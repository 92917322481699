import React from 'react'
import Layout, { LAYOUT_TYPES } from '../modules/Layout'
import { graphql } from 'gatsby'
import Main from '../modules/Main'
import useSeo from '../hooks/useSeo'
import { TEMPLATE_TYPES } from '../common/constants'

const DATA_MAP = {
  title: ['name'],
}
const getLayout = (data, helmetProps) => {
  return [
    ...data.layout,
    {
      __typename: LAYOUT_TYPES.HELMET,
      ...helmetProps,
    },
  ]
}

const PageTemplate = (props) => {
  const {
    data: { contentfulPage },
    pageContext: { slug },
  } = props

  const url = props.location.href
  const seoProps_Home = useSeo(contentfulPage, DATA_MAP, TEMPLATE_TYPES.HOME)
  const seoProps_Page = useSeo(contentfulPage, DATA_MAP, TEMPLATE_TYPES.PAGE)

  const layout = getLayout(contentfulPage, slug === 'home' ? seoProps_Home : seoProps_Page)

  return (
    <Main {...props} path={url}>
      <Layout layout={layout} path={url} parent={contentfulPage} />
    </Main>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query pageQuery($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      id
      slug
      name
      layout {
        __typename
        ... on ContentfulHero {
          ...Hero
        }
        ... on ContentfulImage {
          image {
            title
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        ... on ContentfulLayoutLeftRight {
          ...LayoutLeftRight
        }
        ... on ContentfulCard {
          ...Card
        }
        ... on ContentfulActionBar {
          ...ActionBar
        }
        ... on ContentfulQuote {
          ...Quote
        }
        ... on ContentfulLayoutGrid {
          ...Grid
        }
        ... on ContentfulContentGeneric {
          id
          ...ContentGeneric
        }
        ... on ContentfulVideo {
          id
          ...Video
        }
        ... on ContentfulLayoutBadges {
          id
          ...Badges
        }
        ... on ContentfulLayoutGallery {
          id
          ...LayoutGallery
          layout {
            __typename
            ...Quote
            ...ContentGeneric
          }
        }
        ... on ContentfulLayoutAccordion {
          ...LayoutAccordion
        }
        ... on ContentfulVwoWrapper {
          ...VWO
        }
      }
      structuredData {
        altText
        noIndex
        noFollow
        description {
          description
        }
        headline
        image {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        schema {
          _context
          _type
          mainEntity {
            _type
            name
            acceptedAnswer {
              _type
              text
            }
          }
        }
      }
      hideSubscription
    }
  }
`
